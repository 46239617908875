import * as React from 'react'
import * as ProgressBar from '@radix-ui/react-progress'
import cn from 'classnames'
import styles from './Progress.module.scss'

export type ProgressProps = {
  /**
   * An optional label to show above the progress bar, this will be appended with the progress %
   * @example <ProgressBar label="Setup" /> // Setup: 50%
   */
  label?: string
  /**
   * The current progress of the progress bar, this is the portion which will show filled with colour
   * @example <ProgressBar value={45} />
   */
  value: number
  wrapperClassName?: string
  rootClassName?: string
  indicatorClassName?: string
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'>

export const Progress: React.VFC<ProgressProps> = (props) => {
  const {
    label,
    value,
    wrapperClassName,
    rootClassName,
    indicatorClassName,
    ...restProps
  } = props

  return (
    <div className={cn(styles.progress, wrapperClassName)} {...restProps}>
      {label && (
        <p className={styles.label}>
          {label}: {value}%
        </p>
      )}
      <ProgressBar.Root
        value={value}
        className={cn(styles.root, rootClassName)}
      >
        <ProgressBar.Indicator
          className={cn(styles.indicator, indicatorClassName)}
          style={{ width: `${value}%` }}
        />
      </ProgressBar.Root>
    </div>
  )
}
